
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import { VForm } from 'vuetify/types/custom';
import intl from '@/utils/intl';
import validators from '@/utils/validators';

interface LoginError {
  username: [];
  password: [];
  message: string;
}

export default Vue.extend({
  mixins: [validators],
  data() {
    return {
      loading: false,
      loginForm: false,
      username: '',
      password: '',
      errorMessage: '',
      userErrorMessages: [],
      passErrorMessages: [],
      passShow: false
    }
  },
  computed: {
    buttonText(): string {
      return intl.t('views.login.button');
    },
    userFieldLabel(): string {
      return intl.t('views.login.username');
    },
    passwordFieldLabel(): string {
      return intl.t('views.login.password');
    },
    forgotButtonText(): string {
      return intl.t('views.forgotPassword.title') + '?';
    }
  },
  methods: {
    async login(): Promise<void> {
      if ((this.$refs.loginForm as VForm).validate()) {
        this.loading = true;
        try {
          this.errorMessage = '';
          await this.$store.dispatch('logIn', { username: this.username, password: this.password });
          await this.$router.push((this.$route.query.redirect || '/') as RawLocation);
        } catch (error) {
          const loginError = error as LoginError;
          if (loginError) {
            if ('username' in loginError) {
              this.userErrorMessages = loginError.username;
            }
            if ('password' in loginError) {
              this.passErrorMessages = loginError.password;
            }
            this.errorMessage = loginError.message;
          } else {
            this.errorMessage = intl.t('general.noConnection');
          }
        }
        this.loading = false;
      }
    }
  }
});
